import { combineReducers } from 'redux';
import homeRegistry, { HomeRegistryStateType } from '@fuse/cognito/dist/Reducer/HomeRegistry';
import session, { SessionStoreStateType } from '@fuse/cognito/dist/Reducer/Session';

export interface RootState {
  session: SessionStoreStateType;
  homeRegistry: HomeRegistryStateType;
}

export default combineReducers({
  session,
  homeRegistry,
});
