import * as React from 'react';
import { Link } from 'react-router-dom';

interface FourOhFourProps {
  message?: string;
  status?: number;
  customGenerated?: boolean; // error generated by frontend.
  fallbackUrl?: string;
}
class FourOhFour extends React.Component<FourOhFourProps> {
  render() {
    const { message, status, fallbackUrl, customGenerated } = this.props;
    return (
      <div className="error-bg 404">
        <div className="error-box">
          <figure>
            <div className="fig-img">
              <img alt="" src="/images/progress-symbol.svg" />
            </div>
            <figcaption>
              <h2>
                {!customGenerated ? 'Whoops!' : ''} Error {status || 404}
              </h2>
              <p>{message || 'Looks like there is no way ahead.'}</p>
              <Link to={fallbackUrl || '/'}>
                <button className="btn btn-sm btn-blue">{!fallbackUrl ? 'Go Home' : 'Go Back'}</button>
              </Link>
            </figcaption>
          </figure>
        </div>
      </div>
    );
  }
}

export default FourOhFour;
