import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import config, { activeEnv } from './config/common';
import MainRoutes from './routes';
import { Provider } from 'react-redux';
import Store from './store';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import '@fuse/design/dist/school/style.css';
import './styles/style.min.scss';

if (activeEnv) {
  Sentry.init({
    release: 'fuse-ai-admin@' + config.package_version,
    dsn: config.sentry_url,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Provider store={Store}>
    <React.StrictMode>
      <BrowserRouter>
        <MainRoutes />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root') as HTMLElement,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
