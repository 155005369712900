export const ROLE_USER = 'ACADEMY_STUDENT';
export const ROLE_INSTRUCTOR = 'ACADEMY_INSTRUCTOR';
export const ROLE_ADMIN = 'ACADEMY_ADMIN';
export const ROLE_MARKETING = 'ACADEMY_MARKETING';
export const ROLE_PROGRAM_COORDINATOR = 'ACADEMY_PROGRAM_COORDINATOR';
export const ROLE_PROGRAM_MODERATOR = 'ACADEMY_PROGRAM_MODERATOR';
export const ACADEMY_SUPER_ADMIN = 'ACADEMY_SUPER_ADMIN';
export const ROLE_WEBSITE_DASHBOARD = 'WEBSITE_DASHBOARD_VIEW';
export const ACADEMY_CLASS_INSTRUCTOR = 'ACADEMY_CLASS_INSTRUCTION';
export const ACADEMY_FINANCE_MANAGER = 'ACADEMY_FINANCE_MANAGER';
export const PRODUCT_TEAM = 'PRODUCT_TEAM';

export const ALL_ROLES = [
  ROLE_USER,
  ROLE_INSTRUCTOR,
  ROLE_ADMIN,
  ROLE_MARKETING,
  ROLE_PROGRAM_COORDINATOR,
  ROLE_PROGRAM_MODERATOR,
  ACADEMY_SUPER_ADMIN,
  ROLE_WEBSITE_DASHBOARD,
  ACADEMY_CLASS_INSTRUCTOR,
  ACADEMY_FINANCE_MANAGER,
  PRODUCT_TEAM,
];
