import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import ErrorPage from './scenes/404';
import { ALL_ROLES } from 'src/constants/RoleConstant';
import PublicRoute from '@fuse/cognito/dist/utils/PublicRoute';
import PrivateRoute from '@fuse/cognito/dist/utils/PrivateRoute';
import { LoaderWrap } from '@fuse/ui-components/dist/LoaderWrap';
import {
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
  ROOT_ROUTE,
  NOT_FOUND_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  CHANGE_PASSWORD_ROUTE,
} from '@fuse/cognito/dist/constants';

const ForgotPassword = React.lazy(() => import('@fuse/cognito').then(module => ({ default: module.ForgotPassword })));
const ChangePassword = React.lazy(() => import('@fuse/cognito').then(module => ({ default: module.ChangePassword })));
const Login = React.lazy(() => import('@fuse/cognito/dist/Login').then(module => ({ default: module.Login })));
const Logout = React.lazy(() => import('@fuse/cognito/dist/Logout').then(module => ({ default: module.Logout })));
const PrivateRoutes = React.lazy(() => import('./PrivateRoutes'));

const makeMainRoutes = () => {
  return (
    <Suspense fallback={<LoaderWrap isLoading={true} type="Main" />}>
      <Switch>
        <Route exact path={NOT_FOUND_ROUTE} component={ErrorPage} />
        <Route exact path={LOGOUT_ROUTE} component={Logout} />
        <PublicRoute exact path={FORGOT_PASSWORD_ROUTE} component={ForgotPassword} />
        <PublicRoute exact path={CHANGE_PASSWORD_ROUTE} component={ChangePassword} />
        <PublicRoute exact path={LOGIN_ROUTE} component={Login} />

        <PrivateRoute path={ROOT_ROUTE} component={PrivateRoutes} roles={ALL_ROLES} />
      </Switch>
    </Suspense>
  );
};

export default makeMainRoutes;
